import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as component_45stubznTnSdZ9ocMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubznTnSdZ9oc } from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___de-ch",
    path: "/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___it-ch",
    path: "/it-ch/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "slug___fr-ch",
    path: "/fr-ch/:slug(.*)*",
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubznTnSdZ9ocMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubznTnSdZ9oc
  }
]