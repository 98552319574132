import revive_payload_client_1EYSaiZBHh from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_acYVVZIHGM from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Yrod6Lknon from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jmKfBnOcxi from "/vercel/path0/node_modules/.pnpm/nuxt-site-config@2.2.14_@nuxt+devtools@1.3.9_@vue+compiler-core@3.4.31_nuxt@3.12.3_postcss@8._uvfrlnp25jlpjsznvbvguvj6yq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_kn4NlTgHx3 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kndmLYpL6f from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CwFMQeaQGD from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_A14VnyMzz5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_P03clYFaOn from "/vercel/path0/node_modules/.pnpm/nuxt@3.12.3_@types+node@20.14.10_eslint@8.57.0_rollup@4.18.1_sass@1.77.6_stylelint@14.16.1_ty_mo5r5kseykoftyl7lcrbvcviye/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_83vPnToAmu from "/vercel/path0/node_modules/.pnpm/nuxt-lenis@1.2.3_react-dom@18.3.1_react@18.3.1_rollup@4.18.1/node_modules/nuxt-lenis/dist/runtime/plugin.mjs";
import i18n_x3zNa5vUA4 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.18.1_vue@3.4.31/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import app_init_hybrid_5gOhL5QG1b from "/vercel/path0/plugins/app-init-hybrid.js";
import app_init_client_lDwNIL1JCF from "/vercel/path0/plugins/app-init.client.js";
import chunk_error_handler_wTHYgqOJwi from "/vercel/path0/plugins/chunk-error-handler.js";
import sb_preview_757msyeJ0g from "/vercel/path0/plugins/sb-preview.js";
import sentry_client_GoGQuZo4Et from "/vercel/path0/plugins/sentry.client.js";
import vue_gtag_S6KjvYXIWb from "/vercel/path0/plugins/vue-gtag.js";
export default [
  revive_payload_client_1EYSaiZBHh,
  unhead_acYVVZIHGM,
  router_Yrod6Lknon,
  _0_siteConfig_jmKfBnOcxi,
  payload_client_kn4NlTgHx3,
  navigation_repaint_client_kndmLYpL6f,
  check_outdated_build_client_CwFMQeaQGD,
  chunk_reload_client_A14VnyMzz5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_P03clYFaOn,
  plugin_83vPnToAmu,
  i18n_x3zNa5vUA4,
  app_init_hybrid_5gOhL5QG1b,
  app_init_client_lDwNIL1JCF,
  chunk_error_handler_wTHYgqOJwi,
  sb_preview_757msyeJ0g,
  sentry_client_GoGQuZo4Et,
  vue_gtag_S6KjvYXIWb
]